.FortuneModal {
	font-family: "Comic Neue", cursive;
	max-width: 30%;
	background-color: pink;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
	border: 2px solid #000;
	box-shadow: 24;
	padding: 2vh;
}

.modal-header {
	text-align: center;
	display: inline-block;
	width: 100%;
	margin-right: -50%;
}

.close {
	float: right;
}
