.App {
	font-family: sans-serif;
	text-align: center;
	min-height:100%;
	height: 100%;
}

/* button[type="submit"]:hover {
	pointer-events: none;
	cursor: none;
	transform: translate(50px, 50px);
} */
