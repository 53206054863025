.Pogs {
	text-align: center;
	max-width: 100%;
	font-family: "Comic Neue", cursive;
	background-image: url("../assets/pogs-background.jpg");
	background-repeat: repeat;
	background-size: 100%;
	min-height: 97vh;
	display: flex;
	flex-direction: column;
}

h2 {
	margin: 0;
	padding-top: 5vh;
}

.pogs-container {
	width: 60%;
	background-color: white;
	justify-content: center;
	margin: auto;
	font-family: "Comic Neue", cursive;
	padding: 2vh;
}

.pogsGIF {
	max-width: 20vh;
}

.wordart-pogs {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(#eef05e, #e57f0b 99%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	transform: skewY(-8deg);
	font-family: "Impact";
	font-size: 2vh;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: black;
	margin: auto;
	padding: 2vh;
}
