.Home {
	background-image: url("../assets/water2.png");
	background-repeat: repeat;
	background-size: 20%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.body-container {
	width: 60%;
	background-color: white;
	justify-content: center;
	margin: auto;
	padding: 0 5vh 0 5vh;
	text-align: center;
	font-family: "Comic Neue", cursive;
}

.construction {
	max-width: 100%;
	padding-top: 5vh;
}

.no {
	max-width: 2vh;
}

.main-body {
	padding-bottom: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.warning-text {
	padding-top: 2vh;
}

.main-title {
	display: flex;
	flex-direction: row;
	padding: 3vh 0;
}

.schedule {
	min-width: 100vh;
	display: flex;
	flex-direction: row;
}

.schedule img {
	min-width: 50%;
	padding: 2vh 1vh;
}

.baby {
	max-width: 10vh;
}

/* mobile/tablet */
@media only screen and (max-width: 821px) {
	.subtitle {
		font-size: 1vh;
	}
	.warning-text {
		font-size: 1vh;
	}
	.no {
		max-width: 1vh;
	}
	.lineup {
		max-width: 40vh;
	}
	.baby {
		max-width: 4vh;
		max-height: 5vh;
	}

	.schedule {
		display: flex;
		flex-direction: column;
		min-width: 50vh;
		align-items: center;
	}
}
